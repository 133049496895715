.login-container {
    margin-top: 175px;
    padding: 25px;
    text-align: center;
}

.login-container .divider-login {
    margin-bottom: 25px;
}

.login-container h4 {
    text-align: center !important;
}

.firebaseui-idp-list {
    list-style: none;
    padding: 0;
    margin: 0
}

.playNow {
    padding: 20px !important;
    margin-bottom: 20px !important;
}