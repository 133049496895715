.hud-container {
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    color: #ffffff;
    font-size: .8rem;
    background: rgba(0, 0, 0, .9);
    padding: 15px;
    width: 100%;
}

.hud-container .username {
    padding: 0;
    margin: 0;
    opacity: .5;
    text-decoration: underline;
    text-decoration-style: dashed;
}

.hud-container .score {
    padding: 0;
    margin: 0;
    opacity: .5;
}

.logo {
    width: 30px;
    // position: absolute;
    // right: 10px;
    // top: 7px;
    // opacity: .5
}