@import '../theme/default.scss';
.fab-add-reaction {
    position: fixed !important;
    bottom: 20px !important;
    right: 20px !important;
    background: $primaryColor !important;
}

.fab-settings-reaction {
    position: fixed !important;
    bottom: 20px !important;
    left: 20px !important;
    z-index: 5;
    background: $primaryColor !important;
}

.reactor-down {
    color: #270a36;
    max-width: 100%;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(121, 108, 108, .6);
    margin-top: 100px;
    font-size: 1.3rem;
}

.game-session-list-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    margin-top: 75px
}