@import url('https://fonts.googleapis.com/css?family=Lexend+Deca|Codystar&display=swap');
@import '../theme/default.scss';
body {
    background: #ad5389;
    background: -webkit-linear-gradient(to bottom, #3c1053, #ad5389);
    background: linear-gradient(to bottom, #3c1053, #ad5389);
    min-height: 100vh;
    font-family: 'Lexend Deca', sans-serif;
    color: #ffffff;
    overflow: hidden;
}

img {
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

h1.title {
    text-align: center;
    margin: 25px 0 0 0 !important;
    font-size: 20px !important;
    opacity: .2 !important;
    font-family: 'Lexend Deca', sans-serif;
}

.hidden {
    display: none !important;
}

.sidebar {
    background: #000 url('../img/bg-mountains.jpg') no-repeat;
    width: 240px;
}


/* CSS Hack to hide selection side effects in browser */

// https://stackoverflow.com/questions/16432909/remove-blue-highlight-over-html-image-when-clicked

/*IE9*/

*::selection {
    background-color: transparent;
}

*::-moz-selection {
    background-color: transparent;
}

* {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    /*IE10*/
    -ms-user-select: none;
    user-select: none;
    /*You just need this if you are only concerned with android and not desktop browsers.*/
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input[type="text"],
textarea,
[contenteditable] {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.leaderboard-container {
    display: flex;
    position: fixed;
    left: -13px;
    top: 73px;
    z-index: -2;
    flex-direction: column;
}

.leaderboard-container {
    color: #ffffff;
    ul {
        counter-reset: index;
        padding: 0;
        margin: 0;
    }
    li {
        color: #ffffff;
        counter-increment: index;
        display: flex;
        padding: 2px 0;
        opacity: .4;
    }
    p {
        color: #ffffff;
        margin: 0;
        padding: 0;
        align-self: flex-start;
        text-align: left;
    }
    span {
        margin: 0 0 0 10px;
        padding: 0;
        font-size: .7rem;
        color: #ffffff;
        opacity: .5;
        min-width: 100px;
        text-align: right;
        align-self: flex-end;
    }
    /* Element counter */
    li::before {
        content: counters(index, ".", decimal-leading-zero);
        font-size: .8rem;
        text-align: left;
        font-weight: bold;
        min-width: 25px;
        -webkit-font-feature-settings: "tnum";
        font-feature-settings: "tnum";
        font-variant-numeric: tabular-nums;
        align-self: flex-start;
        opacity: .25;
    }
    // li+li {
    //     border-top: 1px dashed rgba(255, 255, 255, 0.2);
    // }
}

@media screen and (max-width: 414px) {
    .leaderboard-container {
        display: none
    }
}

.leaderboard-you {
    background-color: #ff0000 !important;
}


/* CSS Hack to show outlines for DOM elements on page. Remove for PROD */


/* https://medium.com/@gajus/my-favorite-css-hack-a7f447026cf2 */

// * {
//     background-color: rgba(255, 0, 0, .2);
// }
// * * {
//     background-color: rgba(0, 255, 0, .2);
// }
// * * * {
//     background-color: rgba(0, 0, 255, .2);
// }
// * * * * {
//     background-color: rgba(255, 0, 255, .2);
// }
// * * * * * {
//     background-color: rgba(0, 255, 255, .2);
// }
// * * * * * * {
//     background-color: rgba(255, 255, 0, .2);
// }
// * * * * * * * {
//     background-color: rgba(255, 0, 0, .2);
// }
// * * * * * * * * {
//     background-color: rgba(0, 255, 0, .2);
// }
// * * * * * * * * * {
//     background-color: rgba(0, 0, 255, .2);
// }