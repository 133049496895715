.augment-container-log {
    --aug-tl: 15px;
    --aug-tl-width: 10px;
    --aug-tl-height: 5%;
    --aug-tr: 15px;
    --aug-border: 15px;
    --aug-border-bg: rgba(0, 0, 0, 1);
    --aug-border-fallback-color: #9e7777;
    z-index: 5;
    position: fixed;
    bottom: -600px;
    right: 0;
    width: 100%;
    height: 50%;
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
}

.activity-log-container {
    z-index: 5;
    padding: 20px;
    margin: 10px 0 0 0;
    color: #ffffff;
    ul {
        counter-reset: index;
        padding: 0;
    }
    li {
        color: #ffffff;
        counter-increment: index;
        display: flex;
        padding: 10px 0;
        box-sizing: border-box;
        opacity: .5;
        margin-left: 10px;
        font-size: .8rem;
    }
    p {
        color: #ffffff;
        margin: 0;
        padding: 0;
        align-self: flex-start;
        text-align: left;
    }
    span {
        margin: 0 0 0 10px;
        padding: 0;
        font-size: .7rem;
        color: #ffffff;
        opacity: .5;
        min-width: 100px;
        text-align: right;
        align-self: flex-end;
    }
    /* Element counter */
    li::before {
        content: counters(index, ".", decimal-leading-zero);
        font-size: .8rem;
        text-align: left;
        font-weight: bold;
        min-width: 25px;
        -webkit-font-feature-settings: "tnum";
        font-feature-settings: "tnum";
        font-variant-numeric: tabular-nums;
        align-self: flex-start;
        opacity: .25;
        // background-image: linear-gradient(to bottom, rgb(129, 55, 226), rgba(0, 0, 0, 0));
        // background-attachment: fixed;
        // background-clip: text;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
    }
    li+li {
        border-top: 1px dashed rgba(255, 255, 255, 0.2);
    }
}